"use client";

import React, { useState, useEffect } from "react";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import "./scroll.css";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";

const ScrollToTop = () => {
  const isBrowser = () => typeof window !== "undefined";
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 90) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function scrollToTop() {
    if (!isBrowser()) return;
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <div>
      <button
        className={`fixed bg-orange-500 bottom-5 md:bottom-14 right-0 md:right-4 p-3 rounded-full w-12 h-12 mr-6 z-50 items-center text-xs flex flex-col justify-center gap-2 scrollToTopButton ${
          isVisible ? "visible" : ""
        }`}
        onClick={scrollToTop}
        id="text"
      >
        <p className="flex flex-col justify-center items-center text-black">
          <MdKeyboardDoubleArrowUp />
          Top
        </p>
      </button>
    </div>
  );
};

export default ScrollToTop;

{
  /* <div>
        <Link href="/">
            <FaRegArrowAltCircleUp className='w-10 h-10 text-black fixed bottom-6 right-9 z-[100] bg-orange-400 shadow-2xl rounded-full p-3'/>
        </Link>
    </div> */
}
